var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Locale } from '../../enums/Locale';
import { useLocalization } from '../../providers/LocaleProvider';
import { linkStyles } from '../Link';
import { BodyRegularBR2 } from '../Typography';
var PRIVACY_POLICY_URL = 'https://policies.google.com/privacy';
var TERMS_OF_SERVICE_URL = 'https://policies.google.com/terms';
var getUrl = function (url, locale) {
    var _a;
    var hl = (_a = {},
        _a[Locale.De_At] = 'de',
        _a[Locale.De_Ch] = 'de',
        _a[Locale.De_De_Ffh] = 'de',
        _a[Locale.De_De_H24] = 'de',
        _a[Locale.Fr_Be] = 'fr',
        _a[Locale.Fr_Ch] = 'fr',
        _a[Locale.Fr_Fr] = 'fr',
        _a[Locale.It_It] = 'it',
        _a[Locale.Nl_Be] = 'nl',
        _a[Locale.Nl_Nl] = 'nl',
        _a)[locale];
    return "".concat(url, "?hl=").concat(hl);
};
var StyledText = styled(BodyRegularBR2)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > a {\n    ", ";\n    color: ", ";\n  }\n"], ["\n  & > a {\n    ", ";\n    color: ", ";\n  }\n"])), function (props) {
    return linkStyles(__assign({ styled: true, textDecoration: 'underline' }, props));
}, themeGet('colors.sys.neutral.text.active'));
var ReCaptchaDisclaimer = function (props) {
    var _a = useLocalization(), r = _a.r, locale = _a.locale;
    return (React.createElement(StyledText, __assign({ textAlign: "center", dangerouslySetInnerHTML: {
            __html: r.t('h24_recaptcha_disclaimer', {
                policy_href: getUrl(PRIVACY_POLICY_URL, locale),
                terms_href: getUrl(TERMS_OF_SERVICE_URL, locale),
            }),
        } }, props)));
};
export default ReCaptchaDisclaimer;
var templateObject_1;
